import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import CustomerManagement from "./pages/CustomerManagement";
import Support from "./pages/Support";
import Dashboard from "./pages/Dashboard";
import Supportchat from "./pages/SupportChat";
// import UserActive from "./pages/UserActive";
import CustomerProfile from "./pages/CustomerProfile";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import CommunityManagement from "./pages/CommunityManagement";
import ReportDetails from "./pages/ReportDetails";
import ForgotPasswordtwo from "./pages/ForgotPasswordtwo";
import UserActive from "./pages/UserActive";
import EditNotification from "./pages/EditNotification";
import ServiceProviders from "./pages/ServiceProviders";
import ProvidersProfile from "./pages/ProvidersProfile";
import AllServices from "./pages/AllServices";

import AccountVerification from "./pages/AccountVerification";
import AccountProfile from "./pages/AccountProfile";
import ServiceCategories from "./pages/ServiceCategories";
import AddCategory from "./pages/AddCategory";
import SOSManagement from "./pages/SOSManagement";
import AddSOSNumber from "./pages/AddSOSNumber";
import OrderManagement from "./pages/OrderManagement";
import OrderDetails from "./pages/OrderDetails";
import Usersuspend from "./pages/UserSuspend";
import EventView from "./pages/EventView";
import CreateEvent from "./pages/CreateEvent";
import Faq from "./pages/Faq";
import AddFaq from "./pages/AddFaq";
import TrainingMaterial from "./pages/TrainingMaterial";
import AddTutorial from "./pages/AddTutorial";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentError from "./pages/PaymentError";
import ServiceProviderProfile from "./pages/ServiceProviderProfile";
import UserProfile from "./pages/UserProfile";
import PaymentManagement from "./pages/PaymentManagement";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ServiceDetails from "./pages/ServiceDetails";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Otp" element={<LoginOtp />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/ChangePasswordtwo" element={<ForgotPasswordtwo />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failed" element={<PaymentError />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route element={<ProtectedRoutes />}>
            <Route
              path="/CustomerManagement"
              element={<CustomerManagement />}
            />
            <Route path="/Support" element={<Support />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/CustomerProfile/:id" element={<CustomerProfile />} />
            <Route path="/Supportchat" element={<Supportchat />} />
            {/* <Route path="/UserActive" element={<UserActive />} /> */}
            <Route path="/Notification" element={<Notification />} />
            <Route path="/AddNotification" element={<AddNotification />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route
              path="/CommunityManagement"
              element={<CommunityManagement />}
            />
            <Route path="/ReportDetails" element={<ReportDetails />} />

            <Route path="/ActiveUser" element={<UserActive />} />
            <Route path="/SuspendUser" element={<Usersuspend />} />
            <Route path="/EditNotification" element={<EditNotification />} />
            <Route path="/ServiceProviders" element={<ServiceProviders />} />
            <Route path="/ProvidersProfile" element={<ProvidersProfile />} />
            <Route path="/AllServices" element={<AllServices />} />
            <Route
              path="/service-provider-profile/:id"
              element={<ServiceProviderProfile />}
            />
            <Route
              path="/AccountVerification"
              element={<AccountVerification />}
            />
            <Route path="/AccountProfile" element={<AccountProfile />} />
            <Route path="/ServiceCategories" element={<ServiceCategories />} />
            <Route path="/AddCategory" element={<AddCategory />} />
            <Route path="/SOSManagement" element={<SOSManagement />} />
            <Route path="/AddSOSNumber" element={<AddSOSNumber />} />
            <Route path="/OrderManagement" element={<OrderManagement />} />
            <Route
              path="/OrderDetails/:id"
              element={<OrderDetails/>}
            />
            <Route path="/EventView" element={<EventView />} />
            <Route path="/CreateEvent" element={<CreateEvent />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/AddFaq" element={<AddFaq />} />
            <Route path="/TrainingMaterial" element={<TrainingMaterial />} />
            <Route path="/AddTutorial" element={<AddTutorial />} />
            <Route path="/user-profile/:id" element={<UserProfile />} />
            <Route path="/payment-management" element={<PaymentManagement />} />
            <Route path="/service-details/:id" element={<ServiceDetails />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
