import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { FaRegFilePdf } from "react-icons/fa";

import { accountVerificationdetails } from "../Redux/Actions/adminAction";
import logo from "../Assets/Images/placeholder 1 .png";

import { useDispatch } from "react-redux";

export default function UserProfile() {
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const [Accountdetails, setAccountdetails] = useState({});
  const [referencedata, setReference] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const getAccountdata = async () => {
      const data = await dispatch(accountVerificationdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setAccountdetails(data?.payload?.data?.user);
        setReference(data?.payload?.data?.user_reference);
      }
    };
    getAccountdata();
  }, [id]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Account Verification Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <div className="all-services">
              <div className="product-tab-left">
                <Link to="/AccountVerification">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>
                  Back to previous page
                </Link>
              </div>
            </div>

            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main mb-4">
                  <Row>
                    <Col lg={12} xl={12} xxl={12}>
                      <Figure className="d-flex">
                        <span>
                          <img
                            width={171}
                            height={180}
                            alt="171x180"
                            src={`${BASE_URL}public${Accountdetails?.profile_image}`}
                          />
                        </span>
                      </Figure>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main  px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Profile info</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Owner name</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={Accountdetails?.full_name || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Company name</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={Accountdetails?.company || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Phone number</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={
                                    Accountdetails?.country_code &&
                                    Accountdetails?.phone_number
                                      ? `${Accountdetails?.country_code} ${Accountdetails?.phone_number}`
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  value={Accountdetails?.email || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>House Number</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={Accountdetails?.house_number || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={
                                    Accountdetails?.street_address || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Suburbs</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={Accountdetails?.suburbs || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={Accountdetails?.country || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Post Code or PO Box</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={Accountdetails?.post_code || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Landmark</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={Accountdetails?.landMark || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="user-profile-main mt-4 px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Reference employee details</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Employee name</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={referencedata?.reference_name || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Phone number</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={
                                    referencedata
                                      ?.country_code &&
                                    referencedata?.phone_number
                                      ? `${referencedata?.country_code} ${referencedata?.phone_number}`
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  value={
                                    referencedata
                                      ?.referenceEmail || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Relation with compnay</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={
                                    referencedata?.relation ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Employee designation </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={
                                    referencedata
                                      ?.designation || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  {/* <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Profile Action</h2>
                          <div className="over-view-btn reject-btn">
                          <button>Accept</button>
                          <button>Reject</button>
                        </div>
                      </div>
                    </div>
                  </Col> */}

                  <Col lg={12}>
                    <div className="user-profile-main mt-3 px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">SOCIAL MEDIA</h2>
                        <div className="active-default-links">
                          <div className="activity-list-user social-media">
                            <ul>
                              <li>
                                <p>Facebook</p>
                                <span>
                                  {Accountdetails?.facebook_link ? (
                                    <a
                                      href={Accountdetails.facebook_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {Accountdetails.facebook_link}
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </li>

                              <li>
                                <p>Facebook</p>
                                <span>
                                  {Accountdetails?.instagram_link ? (
                                    <a
                                      href={Accountdetails.instagram_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {Accountdetails.instagram_link}
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </li>

                              <li>
                                <p>Facebook</p>
                                <span>
                                  {Accountdetails?.website_link ? (
                                    <a
                                      href={Accountdetails.website_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {Accountdetails.website_link}
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} className="mt-3">
                    <div className="user-profile-main mt-4 px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Documents</h2>
                        <div className="active-default-links">
                          <div className="documents-box">
                            <div className="govt-doc">
                              <p>Government ID</p>
                              <div className="enter-box">
                                {Accountdetails?.govtIssueId?.includes(
                                  "pdf"
                                ) ? (
                                  <a
                                    href={`${BASE_URL}public/${Accountdetails?.govtIssueId}`}
                                    target="_blank"
                                  >
                                    <FaRegFilePdf size={100} />
                                  </a>
                                ) : Accountdetails?.govtIssueId?.includes(
                                    "jpg"
                                  ) ||
                                  Accountdetails?.govtIssueId?.includes(
                                    "jpeg"
                                  ) ||
                                  Accountdetails?.govtIssueId?.includes(
                                    "png"
                                  ) ? (
                                  <img
                                    src={`${BASE_URL}public/${Accountdetails.govtIssueId}`}
                                    alt="Pan Card"
                                  />
                                ) : (
                                  "Not an image or PDF"
                                )}
                              </div>
                            </div>
                            <div className="govt-doc">
                              <p>Business licence</p>
                              <div className="enter-box">
                                {Accountdetails?.businessLicence?.includes(
                                  "pdf"
                                ) ? (
                                  <a
                                    href={`${BASE_URL}public/${Accountdetails?.businessLicence}`}
                                    target="_blank"
                                  >
                                    <FaRegFilePdf size={100} />
                                  </a>
                                ) : Accountdetails?.businessLicence?.includes(
                                    "jpg"
                                  ) ||
                                  Accountdetails?.businessLicence?.includes(
                                    "jpeg"
                                  ) ||
                                  Accountdetails?.businessLicence?.includes(
                                    "png"
                                  ) ? (
                                  <img
                                    src={`${BASE_URL}public/${Accountdetails.businessLicence}`}
                                    alt="Pan Card"
                                  />
                                ) : (
                                  "Not an image or PDF"
                                )}
                              </div>
                            </div>
                            <div className="govt-doc">
                              <p>Permit</p>
                              <div className="enter-box">
                                {Accountdetails?.permit?.includes("pdf") ? (
                                  <a
                                    href={`${BASE_URL}public/${Accountdetails?.permit}`}
                                    target="_blank"
                                  >
                                    <FaRegFilePdf size={100} />
                                  </a>
                                ) : Accountdetails?.permit?.includes("jpg") ||
                                  Accountdetails?.permit?.includes("jpeg") ||
                                  Accountdetails?.permit?.includes("png") ? (
                                  <img
                                    src={`${BASE_URL}public/${Accountdetails.permit}`}
                                    alt="Pan Card"
                                  />
                                ) : (
                                  "Not an image or PDF"
                                )}
                              </div>
                            </div>
                            <div className="govt-doc">
                              <p>Certifications</p>
                              <div className="enter-box">
                                {Accountdetails?.certifications?.includes(
                                  "pdf"
                                ) ? (
                                  <a
                                    href={`${BASE_URL}public/${Accountdetails?.certifications}`}
                                    target="_blank"
                                  >
                                    <FaRegFilePdf size={100} />
                                  </a>
                                ) : Accountdetails?.certifications?.includes(
                                    "jpg"
                                  ) ||
                                  Accountdetails?.certifications?.includes(
                                    "jpeg"
                                  ) ||
                                  Accountdetails?.certifications?.includes(
                                    "png"
                                  ) ? (
                                  <img
                                    src={`${BASE_URL}public/${Accountdetails.certifications}`}
                                    alt="Pan Card"
                                  />
                                ) : (
                                  "Not an image or PDF"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
