import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";
import {
  ServiceproviderDelete,
  SuspendServiceprovider,
  getServiceProviderDetails,
  serviceproviderdata,
} from "../Redux/Actions/adminAction";

export default function ServiceProviderProfile() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [serviceproviderDetails, setdetails] = useState({});
  const [Activities, setactivities] = useState([]);

  const [show, setshow] = useState(false);
  const [showd, setshowd] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(serviceproviderdata({ id: id }));
      console.log(data);
      if (data?.payload?.data?.success === true) {
        setdetails(data?.payload?.data?.data);
        setactivities(data?.payload?.data?.activities);
      }
    };
    getuserdata();
  }, [id]);


  const handledelclick = async () => {
    setshowd(true);
  };

  const handleclick = async () => {
    setshow(true);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Service Provider Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <div className="all-services">
              <div className="product-tab-left">
                <Link to="/ServiceProviders">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>
                  Back to previous page
                </Link>
              </div>
              <div className="over-view-btn">
                <button>Overview</button>
                <button onClick={() => navigate("/AllServices")}>
                  All Services
                </button>
              </div>
            </div>

            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              {/* <div className="cmn-btn">
                <Link to="/ProvidersProfile" className="me-3">
                  Overview
                </Link>
                <Link to="/AllServices">Service details </Link>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main mb-4">
                  <Row>
                    <Col lg={12} xl={12} xxl={12}>
                      <Figure className="d-flex">
                        <span>
                          <img
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              serviceproviderDetails?.serviceProviderId
                                ?.profile_image
                                ? `${url}public${serviceproviderDetails?.serviceProviderId?.profile_image}`
                                : logo
                            }
                          />
                        </span>
                        <Figure.Caption>
                          <h2>
                            {serviceproviderDetails?.serviceProviderId
                              ?.full_name || "N/A"}
                          </h2>
                          <p>
                            {serviceproviderDetails?.serviceProviderId?.email ||
                              "N/A"}
                          </p>
                          <p>
                            {serviceproviderDetails?.serviceProviderId
                              ?.phone_number
                              ? serviceproviderDetails?.serviceProviderId
                                  .country_code
                                ? `${serviceproviderDetails?.serviceProviderId.country_code} ${serviceproviderDetails?.serviceProviderId.phone_number}`
                                : `${serviceproviderDetails?.serviceProviderId.phone_number}`
                              : "N/A"}
                          </p>
                        </Figure.Caption>
                      </Figure>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Service info</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Owner Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Owner Name"
                                  value={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.full_name || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            {/* <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Owner Name</Form.Label>
                                  <Form.Control type="text" placeholder="Owner Name" />
                                </Form.Group>
                              </Col> */}
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Company Name"
                                  value={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.company_name || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Dummy"
                                  value={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.country_code &&
                                    serviceproviderDetails?.serviceProviderId
                                      ?.phone_number
                                      ? `${serviceproviderDetails?.serviceProviderId?.country_code} ${serviceproviderDetails?.serviceProviderId?.phone_number}`
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  value={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.email || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>House Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="House Number"
                                  value={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.house_number || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Street Address"
                                  value={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.address || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Suburbs</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Suburbs"
                                  value={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.suburbs || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Country"
                                  value={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.country || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Post Code or PO Box</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Post Code"
                                  value={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.post_code || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Landmark</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Landmark"
                                  value={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.landMark || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  {/* <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-2">Photos</h2>
                        <div className="images-gallery">
                          <Row>
                            {serviceDetails?.images?.map((res) => {
                              return (
                                <Col lg={6}>
                                  <div className="image-box-item">
                                    <img
                                      src={
                                        res
                                          ? `https://simba-api.bosselt.com/public/user/${res}`
                                          : "Images Not Found"
                                      }
                                    />
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col> */}

                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">SOCIAL MEDIA</h2>
                        <div className="active-default-links">
                          <div className="activity-list-user social-media">
                            <ul>
                              <li>
                                <p>Facebook</p>
                                <span>
                                  {serviceproviderDetails?.serviceProviderId
                                    ?.facebook_link ? (
                                    <a
                                      href={
                                        serviceproviderDetails.serviceProviderId
                                          .facebook_link
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    ></a>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </li>
                              <li>
                                <p>Instagram</p>
                                <span>
                                  {serviceproviderDetails?.serviceProviderId
                                    ?.instagram_link ? (
                                    <a
                                      href={
                                        serviceproviderDetails.serviceProviderId
                                          .instagram_link
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    ></a>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </li>
                              <li>
                                <p>Website</p>
                                <span>
                                  {serviceproviderDetails?.serviceProviderId
                                    ?.website_link ? (
                                    <a
                                      href={
                                        serviceproviderDetails.serviceProviderId
                                          .website_link
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    ></a>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} className="mt-3">
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Activity</h2>
                        <div className="active-default-links">
                          <div className="activity-list-user">
                            {Activities && Activities.length > 0 ? (
                              Activities.map((res) => (
                                <ul key={res?.id}>
                                  {" "}
                                
                                  <li>
                                    <h6>{res?.title}</h6>
                                    <p>
                                      {res?.createdAt
                                        ? new Date(res?.createdAt)
                                            .toISOString()
                                            .slice(0, 10)
                                            .replace(/-/g, "/")
                                        : "N/A"}
                                    </p>
                                  </li>
                                </ul>
                              ))
                            ) : (
                              <p>Activity not happened yet</p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-3">
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Documents</h2>
                        <div className="active-default-links">
                          <div className="documents-box">
                            <div className="govt-doc">
                              <p>Government ID</p>
                              <div className="enter-box">
                                <img
                                  width={171}
                                  height={180}
                                  alt="171x180"
                                  src={
                                    serviceproviderDetails?.serviceProviderId?.govtIssueId
                                      ? `${url}public/${serviceproviderDetails.serviceProviderId.govtIssueId}`
                                      : "../Assets/Images/profile-images-details.png"
                                  }
                                  
                                />
                              </div>
                            </div>
                            <div className="govt-doc">
                              <p>Business licence</p>
                              <div className="enter-box">
                                <img
                                  width={171}
                                  height={180}
                                  alt="171x180"
                                  src={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.businessLicence
                                      ? `${url}public${serviceproviderDetails?.serviceProviderId?.businessLicence}`
                                      : logo
                                  }
                                />
                              </div>
                            </div>
                            <div className="govt-doc">
                              <p>Permit</p>
                              <div className="enter-box">
                                <img
                                  width={171}
                                  height={180}
                                  alt="171x180"
                                  src={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.permit
                                      ? `${url}public${serviceproviderDetails?.serviceProviderId?.permit}`
                                      : logo
                                  }
                                />
                              </div>
                            </div>
                            <div className="govt-doc">
                              <p>Certifications</p>
                              <div className="enter-box">
                                <img
                                  width={171}
                                  height={180}
                                  alt="171x180"
                                  src={
                                    serviceproviderDetails?.serviceProviderId
                                      ?.certifications
                                      ? `${url}public${serviceproviderDetails?.serviceProviderId?.certifications}`
                                      : logo
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={6} className="mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Delete Account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            Once you delete your account, you can not retrieve
                            the account. Please be certain.
                          </p>
                        </Figure.Caption>
                        <div class="dlt-ac-btn mt-4">
                          <a  style={{ cursor: "pointer" }}
                            onClick={handledelclick}>Delete Account</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} className="mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Suspend account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            You will not be able to receive messages,
                            notifications for up to 24hours.
                          </p>
                        </Figure.Caption>
                        <div class="dlt-ac-btn mt-4">
                          <a   style={{ cursor: "pointer" }}
                            onClick={handleclick}>Suspend Account</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to suspend this account ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(SuspendServiceprovider({ id: id, status: 0 })).then((res) => {
                if (res?.payload?.success) {
                  toast.success(res?.payload?.message);
                  setshow(false);
                  navigate("/ServiceProviders");
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showd} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to delete this account ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(ServiceproviderDelete({ _id: id })).then((res) => {
                console.log(res, "response");
                if (res?.payload?.status === 200) {
                  toast.success(res?.payload?.data?.message);
                  setshowd(false);
                  navigate("/ServiceProviders");
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
